<template>
<div>

  <div  v-if="top">

    <image-param-picker
        @input="$store.state.finemailer.elementEditTrigger++"
        v-model="$store.state.finemailer.selectedElement.content"
        disable-portal
    />

    <a-parameter-slider
        @change="$store.state.finemailer.elementEditTrigger++"
        v-model="$store.state.finemailer.selectedElement.config.width"
        default="100"
        :label="$t('Width')"
        :track-color="wsBACKGROUND"
        :font-color="wsDARKER"
        :value-color="wsACCENT"
        class="pb-6"
        min="0"
        max="100"
        unit="%"
        light
    />

    <ws-text-field
        @change="$store.state.finemailer.elementEditTrigger++"
        v-model="$store.state.finemailer.selectedElement.config.url"
        :label="$t('Link')"
        placeholder="https://"
        avalon-style
        clearable
    />


  </div>

  <div v-else>

  </div>


</div>
</template>

<script>
import imageParamPicker from "@/components/AvalonEditor/UI/imageParamPicker";
export default {
  name: "emailSettingsGeneral",
  components : {
    imageParamPicker
  },
  props : {
    top : {
      type : Boolean,
      default : false
    }
  },
  computed : {},
  methods : {
    updateImage(event) {
      this.notify(event)
    }
  }
}
</script>

<style scoped>

</style>