<template>
<div class="pa-5">

  <!-- Vertical Alignment -->
  <h4  style="font-size : 14px" :style="`color : ${wsDARKER}`"> {{ $t('avalon.image.vertical_align') }}</h4>
  <ws-button-group
      @input="$store.state.finemailer.blockEditTrigger++"
      v-model="$store.state.finemailer.selectedBlock.config[`col_${selectedColumnIndex}_vertical_align`]"
      :items="alignmentSelect"
      class="mb-2 mt-2"
  />

  <!-- Full Height Column -->
  <a-parameter-switch
      label="format.full_height"
      class="mt-6"
      light
  />
  <v-divider class="mt-3 mb-3" :style="`border-color : ${wsDARKLIGHT}; `" />

  <h4 class="mt-4" style="font-size : 14px" :style="`color : ${wsDARKER}`"> {{ $t('Padding') }}</h4>
  <ws-button-group
      v-model="paddingType"
      @input="handlePaddingTypeChange"
      :items="paddingsSelect"
      class="mb-2 mt-2"
      dense
  />
  <div v-if="paddingType === 'custom' ">
    <a-parameter-slider
        @change="$store.state.finemailer.blockEditTrigger++"
        v-model="$store.state.finemailer.selectedBlock.config[`col_${selectedColumnIndex}_padding_top`]"
        default="0"
        :label="$t('PaddingTop')"
        :track-color="wsBACKGROUND"
        :font-color="wsDARKER"
        :value-color="wsACCENT"
        class="pb-6"
        min="0"
        max="50"
        light
    />
    <a-parameter-slider
        @change="$store.state.finemailer.blockEditTrigger++"
        v-model="$store.state.finemailer.selectedBlock.config[`col_${selectedColumnIndex}_padding_bottom`]"
        default="0"
        :label="$t('PaddingBottom')"
        :track-color="wsBACKGROUND"
        :font-color="wsDARKER"
        :value-color="wsACCENT"
        class=" pb-6"
        min="0"
        max="50"
        light
    />
    <a-parameter-slider
        @change="$store.state.finemailer.blockEditTrigger++"
        v-model="$store.state.finemailer.selectedBlock.config[`col_${selectedColumnIndex}_padding_left`]"
        default="0"
        :label="$t('PaddingLeft')"
        :track-color="wsBACKGROUND"
        :font-color="wsDARKER"
        :value-color="wsACCENT"
        class=" pb-6"
        min="0"
        max="50"
        light
    />
    <a-parameter-slider
        @change="$store.state.finemailer.blockEditTrigger++"
        v-model="$store.state.finemailer.selectedBlock.config[`col_${selectedColumnIndex}_padding_right`]"
        default="0"
        :label="$t('PaddingRight')"
        :track-color="wsBACKGROUND"
        :font-color="wsDARKER"
        :value-color="wsACCENT"
        class=" pb-6"
        min="0"
        max="50"
        light
    />
  </div>

  <!-- Background Color -->
  <avalon-color-picker
      @input="$store.state.finemailer.blockEditTrigger++"
      v-model="$store.state.finemailer.selectedBlock.config[`col_${selectedColumnIndex}_background_color`]"
      label="mailer.editor.column.background_color"
      :color-function="EMAIL_COLOR"
      class="mb-4"
      font-regular
      divider
      clearable
  />

  <!-- Border Switch -->
  <a-parameter-switch
      @input="handleBorderChange"
      :value="$store.state.finemailer.selectedBlock.config[`col_${selectedColumnIndex}_border`] > 0"
      label="mailer.editor.column.border"
      class="mt-6"
      light
  />
  <v-divider class="mt-3 mb-3" :style="`border-color : ${wsDARKLIGHT}; `" />

  <!-- Border Thickness -->
  <a-parameter-slider
      @change="$store.state.finemailer.blockEditTrigger++"
      v-model="$store.state.finemailer.selectedBlock.config[`col_${selectedColumnIndex}_border`]"
      default="0"
      :label="$t('mailer.editor.block.border_value')"
      :track-color="wsBACKGROUND"
      :font-color="wsDARKER"
      :value-color="wsACCENT"
      class="pb-6"
      min="0"
      max="10"
      light
  />

  <!-- Border Color -->
  <avalon-color-picker
      v-if="$store.state.finemailer.selectedBlock.config[`col_${selectedColumnIndex}_border`] > 0"
      @input="$store.state.finemailer.blockEditTrigger++"
      v-model="$store.state.finemailer.selectedBlock.config[`col_${selectedColumnIndex}_border_color`]"
      label="mailer.editor.block.border_color"
      :color-function="EMAIL_COLOR"
      class="mb-4"
      font-regular
      divider
  />

  <!-- Rounded Value -->
  <a-parameter-slider
      v-if="!!$store.state.finemailer.selectedBlock.config[`col_${selectedColumnIndex}_border`]  || !!$store.state.finemailer.selectedBlock.config[`col_${selectedColumnIndex}_background_color`]"
      @change="$store.state.finemailer.blockEditTrigger++"
      v-model="$store.state.finemailer.selectedBlock.config[`col_${selectedColumnIndex}_border_radius`]"
      default="0"
      :label="$t('Rounded')"
      :track-color="wsBACKGROUND"
      :font-color="wsDARKER"
      :value-color="wsACCENT"
      class="pb-6"
      min="0"
      max="200"
      light
  />


</div>
</template>

<script>
import avalonColorPicker from "@/components/AvalonEditor/DesigSystemUI/AvalonColorPicker";
import finemailer from "@/modules/finemailer/mixins/finemailer";
import {mapState} from "vuex";
export default {
  name: "emailSettingsGeneral",
  mixins : [finemailer],
  components : {
    avalonColorPicker
  },
  data() {
    return {
      paddingType : null,
      paddingSizes : [{ name : null , value : 0 } , { name : 's' , value : 8 } , { name :  'm' , value : 24 } , { name : 'l' , value : 50 } ],
      paddingsSelect : [
        { icon: 'mdi-cancel', value: null },
        { text: 'S', value: 's' },
        { text: 'M', value: 'm' },
        { text: 'L', value: 'l' },
        { icon: 'mdi-dots-horizontal', value: 'custom' },
      ],
    }
  },
  computed : {
    ...mapState('finemailer' , [
        'selectedBlock',
        'selectedColumnIndex'
    ]),

    blockUuid() {
      return this.selectedBlock.uuid
    },

    navigationSelect() {
      return [
        { name : this.$t('Paddings') , value : 'paddings' },
      ]
    },

    alignmentSelect() {
      return [
        { icon : 'mdi-format-vertical-align-top' , value : 'top' },
        { icon : 'mdi-format-vertical-align-center' , value : 'middle' },
        { icon : 'mdi-format-vertical-align-bottom' , value : 'bottom' }
      ]
    }
  },
  watch : {
    blockUuid() {
      this.getPaddingType()
    },
    selectedColumnIndex() {
      this.getPaddingType()
    }
  },

  methods : {
    handleBorderChange(value) {
      this.$store.state.finemailer.selectedBlock.config[`col_${this.selectedColumnIndex}_border`] = value ?  1 : 0
      this.$store.state.finemailer.selectedBlock = this.COPY(this.$store.state.finemailer.selectedBlock)
      this.$store.state.finemailer.blockEditTrigger++
    },
    handlePaddingTypeChange(type) {
      let size = this.paddingSizes.find(el => el.name === type)

      if (size) {
        this.$store.state.finemailer.selectedBlock.config[`col_${this.selectedColumnIndex}_padding_top`]    = size.value
        this.$store.state.finemailer.selectedBlock.config[`col_${this.selectedColumnIndex}_padding_bottom`] = size.value
        this.$store.state.finemailer.selectedBlock.config[`col_${this.selectedColumnIndex}_padding_left`]   = size.value
        this.$store.state.finemailer.selectedBlock.config[`col_${this.selectedColumnIndex}_padding_right`]   = size.value
        this.$store.state.finemailer.selectedBlock = this.COPY(this.$store.state.finemailer.selectedBlock)
        this.$store.state.finemailer.blockEditTrigger++
      }

    },
    getPaddingType() {

      this.paddingSizes.forEach(size => {

        if (   this.selectedBlock.config[`col_${this.selectedColumnIndex}_padding_top`]    === size.value
            && this.selectedBlock.config[`col_${this.selectedColumnIndex}_padding_bottom`] === size.value
            && this.selectedBlock.config[`col_${this.selectedColumnIndex}_padding_left`]   === size.value
            && this.selectedBlock.config[`col_${this.selectedColumnIndex}_padding_right`]  === size.value
        ) {
          this.paddingType =  size.name
          return
        }
      })

      if (   !this.selectedBlock.config[`col_${this.selectedColumnIndex}_padding_top`]
          && !this.selectedBlock.config[`col_${this.selectedColumnIndex}_padding_bottom`]
          && !this.selectedBlock.config[`col_${this.selectedColumnIndex}_padding_left`]
          && !this.selectedBlock.config[`col_${this.selectedColumnIndex}_padding_right`]
      ) {
        this.paddingType =  null
        return
      }

    },
  },
  mounted() {
    this.getPaddingType()
  }
}
</script>

<style scoped>

</style>